<template>
  <div class="call">
    <div class="call-header">
      我们在这里等待您的到来
    </div>
    <div class="call-content row">
      <div class="call-content-item" style="background:#D7EBF9;">
        <div>
          <img :src="require('@imgs/aubot/email.png')" />
          <h3>邮箱</h3>
          <span>46538191@qq.com</span>
        </div>
      </div>
      <div class="call-content-QCitem" style="background:#D7EBF9;">
        <div>
          <img :src="require('@imgs/aubot/QCCode.jpg')" />
          <p>扫一扫关注我们</p>
        </div>
      </div>
    </div>
    <div style="height:20px;"></div>
  </div>
</template>
<script>
export default {

}
</script>
<style scoped lang="scss">
.call {
  width: 90%;

  @media (min-width: 1400px) {
    width: 70%;
  }
  @media (min-width: 1100px) {
    width: 80%;
  }

  margin: 0 auto;

  .call-content {
    gap: 40px;
  }

  &-content-item {
    width: 100%;
    height: 260px;
    position: relative;
    flex: 0 0 100%;
    max-width: 100%;

    @media (min-width: 576px) {
      flex: 1;
    }

    img {
      width: 36px;
      height: 36px;
    }

    div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      h3 {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        padding: 20px;
        font-family: Microsoft YaHei;
      }

      span {
        font-size: 15px;
        font-weight: 400;
        color: #666666;
        font-family: Microsoft YaHei;
      }
    }
  }

  &-content-QCitem {
    width: 100%;
    height: 260px;
    position: relative;
    flex: 0 0 100%;
    max-width: 100%;

    @media (min-width: 576px) {
      flex: 1;
    }

    img {
      width: 150px;
      height: 150px;
    }

    div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      p {
        font-size: 20px;
        font-weight: 400;
        color: #333333;
        font-family: Microsoft YaHei;
        margin: 15px 0 0 0;
      }
    }
  }

  &-header {
    font-size: 16px;
    font-weight: 600;
    color: #333333;
    text-align: center;
    padding: 30px;
  }
}
</style>