<template>
  <div class="aubot">
    <div class="aubot-banner">
      <img :src="require('@imgs/aubot/web/banner.png')" v-if="$isMobile()" />
      <img :src="require('@imgs/aubot/banner.png')" v-else />
      <div class="aubot-banner-title">
        <h3>工委会建设</h3>
        <!-- <span>AUBOT US</span> -->
      </div>
    </div>
    <div class="aubot-breadcrumb">
      当前位置:<span @click="$router.push({ path: '/' })">首页</span> -> <span
        @click="$router.push({ path: '/aubot' })">工委会建设</span><span v-if="isActive !== -1"> -> {{ tabs[isActive].title
        }}</span>
    </div>
    <div class="aubot-content">
      <div class="aubot-header flex-row-space-between row">
        <div class="aubot-tabs col-sm-8 col-12">
          <div :class="{ 'aubot-tabs-item': true, 'aubot-tabs-item--active': isActive === index }"
            v-for="(item, index) in tabs" :key="index" @click="tabChange(index)">
            {{ item.title }}
          </div>
        </div>
      </div>
      <div class="aubot-line" v-if="isActive !== -1">
        {{ tabs[isActive].title }}
      </div>
      <div class="new-detail-content ql-editor" v-html="tabs[isActive].content"
        :style="$isMobile() ? 'padding:15px 20px;' : ''"></div>
      <!-- <aubot-special v-if="isActive === '工委会介绍'"></aubot-special> -->
      <!-- <aubot-working v-if="isActive === '所辖工作组'"></aubot-working> -->
      <!-- <about-apply v-if="isActive === '委员资格申请'"></about-apply> -->
      <!-- <aubot-active v-if="isActive === '活动申请'"></aubot-active> -->
      <!-- <aubot-call v-if="isActive === '联系我们'"></aubot-call> -->
      <!-- <aubot-plan v-if="isActive === '年度工作计划'"></aubot-plan> -->
    </div>
    <img :src="require('@imgs/aubot/background.png')" class="aubot-content-bg" />
  </div>
</template>
<script>
import aubotSpecial from './aubot-special'
import aubotWorking from './aubot-working'
import aboutApply from './about-apply'
import aubotActive from './aubot-activeApply'
import aubotCall from './aubot-call'
import aubotPlan from './about-plan.vue'
export default {
  name: 'aubot',
  components: {
    aubotSpecial,
    aubotWorking,
    aboutApply,
    aubotActive,
    aubotCall,
    aubotPlan
  },
  data() {
    return {
      tabs: [],
      isActive: -1
    }
  },
  created() {
    this.getList()
  },
  methods: {
    tabChange(item) {
      this.isActive = item
    },
    getList() {
      this.$http({
        url: '/news/list',
        params: {
          pageNum: 0,
          pageSize: 20
        }
      }).then(res => {
        this.tabs = [...res.rows].sort((a, b) => ['工委会介绍', '委员资格申请', '活动集锦', '联系我们'].findIndex((v) => v === a.title) - ['工委会介绍', '委员资格申请', '活动集锦', '联系我们'].findIndex((v) => v === b.title))
        this.tabChange(0)
      })
    }
  },
  watch: {
    '$route.query': {
      handler(v) {
        if (v.type) {
          this.isActive = v.type
        }
      },
      immediate: true
    }
  }
}
</script>
<style scoped lang="scss">
.aubot {
  position: relative;

  &-content-bg {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: -1;
  }

  &-banner {
    width: 100%;
    position: relative;
    width: 100%;

    img {
      width: 100%;
    }

    &-title {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 5;
      text-align: center;

      h3 {
        font-size: 22px;
        font-weight: 400;
        color: #ffffff;
        line-height: .8;
      }

      span {
        font-size: 1em;
        font-weight: 400;
        color: #ffffff;
        line-height: 28px;
      }
    }
  }

  &-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px 30px 30px 30px;
    background: #fff;
    margin-bottom: 40px;
  }

  &-header {
    align-items: center;
  }

  &-breadcrumb {
    max-width: 1200px;
    margin: 0 auto;
    padding: 12px 0;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    text-align: left;

    span {
      cursor: pointer;
    }
  }

  &-tabs {

    // display: flex;
    &-item {
      padding: 5px 16px;
      font-size: 16px;
      border: 1px solid #eee;
      color: #666666;
      cursor: pointer;
      margin-top: 10px;
      display: inline-block;
    }

    &-item--active {
      background: #2870b6;
      color: #fff;
      border: 0;
    }
  }

  &-line {
    margin-top: 20px;
    font-size: 20px;
    font-weight: 600;
    color: #333333;
    padding: 10px;
    position: relative;
  }

  &-line::before {
    width: 20px;
    height: 2px;
    background: #000;
    position: absolute;
    bottom: 4px;
    content: "";
    display: block;
  }

  &-longLine {
    position: absolute;
    top: 500px;
    left: 0;
    right: 0;
    height: 1px;
    border-top: 1px dotted #666;
  }
}
</style>