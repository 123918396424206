import { render, staticRenderFns } from "./about-apply.vue?vue&type=template&id=5a943d7c&scoped=true&"
var script = {}
import style0 from "./about-apply.vue?vue&type=style&index=0&id=5a943d7c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a943d7c",
  null
  
)

export default component.exports