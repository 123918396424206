<template>
  <div class="aubot-apply">
    <div class="aubot-apply-img">
      <img :src="require('@imgs/aubot/activeApply.png')" :style="$isMobile() ? 'width:70%;': ''"/>
    </div>
    <div style="display:flex;font-size:20px;font-weight:600;margin-top:100px;">
      <div class="aubot-apply-2">
        联系邮箱:
      </div>
      <div>
        <span style="color: #E71415;">vr_edu@126.com</span>
        <p style="font-size:16px;font-weight:400;margin-top:36px;"><span>附件一：<a href="http://0vr1.com/video/2021-06-21/2021/FrontPic/doc/Teacher%20training%20application%20form.docx
" style="color:#007AFF;cursor: pointer;">师资培训申请表.doc</a></span></p>
        <p style="font-size:16px;font-weight:400;"><span>附件二：<a href="http://0vr1.com/video/2021-06-21/2021/FrontPic/doc/Application%20form%20for%20integration%20of%20production%20and%20education.docx
" style="color:#007AFF;cursor: pointer;">产教融合申请表.doc</a></span></p>
      </div>
    </div>
    <div class="aubot-apply-2" style="margin-bottom:36px;margin-top:60px;">
      申请须知:
    </div>
    <div class="aubot-apply-3" style="margin-bottom:60px;">
      <p>一、仅限工委会委员可以申请</p>
      <p>二、活动的主办单位之一为新工科联盟，承办单位为新工科联盟虚拟现实教育工委会以及活动申请单位；</p>
      <p>三、活动承办单位需认真负责、合法守规地进行线上线下的各类活动，因活动所带来的一切责任，均由承办单位承担，包括但不限于收支盈亏、法律责任等，工委会不承担任何责任；</p>
      <p>四、师资培训活动中如果需要由工委会颁发培训证书，仅承办单位考试通过后，新工科联盟可颁发中国软件协会签印的证书，每颁发一个证书联盟将收取一
      定金额的费用；</p>
      <p>五、活动过程中需挂工委会统一的横幅，并在新闻稿中体现；</p>
      <p>六、活动结束后5天内，有活动申请单位向秘书处提交活动新闻稿及显示有工委会横幅的照片3-4幅。</p>
    </div>
    <div class="aubot-apply-2" style="margin-bottom:36px;">
      委员权益:
    </div>
    <div class="aubot-apply-3">
      <p>一、科技信息服务</p>
      <p style="padding-left:30px;">不定期收到学会各种会讯通知及行业资讯提示</p>
      <p>二、优惠参加或举行各种学术会议及活动协会：开展会员管理工作。</p>
      <p style="padding-left:30px;">委员每年有机会利用协会提供的平台，参加或者举办协会VR相关的活动、会议。</p>
      <p>三、VR学术服务</p>
      <p style="padding-left:30px;">可加入学会QQ群、微信群，全国各地VR行业专业人士相互交流平台；</p>
    </div>
  </div>
</template>
<script>
import modal from '@components/modal/modal'
import apply from '@components/apply/apply'
export default {
  name: 'aubotApply',
  components: {
    modal,
    apply
  },
  data () {
    return {
      modal: false,
      successModal: false
    }
  },
  methods: {
    showModal () {
      this.successModal = true
    },
  }

}
</script>
<style scoped lang="scss">
.aubot-apply {
  padding: 10px;
  &-img {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    img {
      width: 500px;
    }
  }
  &-1 {
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    line-height: 32px;
    padding: 20px 0 40px 0;
  }
  &-2 {
    font-size: 20px;
    font-weight: 400;
    color: #0a0a0a;
    min-width: 100px;
  }
  &-3 {
    font-size: 16px;
    font-weight: 400;
    color: #666;
    line-height: 32px;
    p {
      margin-bottom: 0;
    }
  }
  &-4 {
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    line-height: 32px;
  }
  &-process {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  &-item {
    width: 95px;
    height: 95px;
    border-radius: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-index {
    width: 70px;
    height: 70px;
    border-radius: 70px;
    line-height: 70px;
    text-align: center;
    font-size: 34px;
    font-weight: 600;
    color: #d7d7d7;
    background: #fff;
  }
  &-line {
    width: 100px;
    height: 1px;
    border-top: 1px dotted #666;
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translate(-50%, -50%);
  }
  &-content {
    padding: 0 10px;
    position: relative;
    width: 120px;
  }
  &-triangle {
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-left: 10px solid #979797;
    border-bottom: 5px solid transparent;
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translate(-50%, -50%);
  }
  &-item-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 13px;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
    width: 110px;
  }
  &-but {
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    cursor: pointer;
    background: #0862ca;
    color: #fff;
    width: 90px;
    text-align: center;
  }
}
.aubot-success {
  width: 80vw;
  background: #fff;
  max-width: 530px;
  padding-bottom: 10px;
  &-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    padding-top: 20px;
    flex-direction: column;
    img {
      width: 100px;
    }
  }
}
</style>