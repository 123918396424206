<template>
  <div class="vrModal" v-show="show" @click="close">
    <div class="vrModal-content">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'modal',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  model: {
    event: 'showModal',
    prop: 'show'
  },
  methods: {
    close () {
      this.$emit('showModal', !this.show)
    }
  }
}
</script>
<style scoped>
.vrModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}
</style>